const Footer = () => {
    return (
        <>
            <footer className="footer">
                <p className="copyright">&copy; 2022 Digital !D - Direitos Reservados</p>
            </footer>
        </>
    )
}

export default Footer;