const Fundo = () => {
    return (
        <>
            <section className="background-wrapper">
                <div className="escopo-main">
                    <div className="caixa-wrapper">
                        <div className="icones">
                            <div className="sessao-principal">
                                <a href="https://digitalid.com.br/" target="blank">
                                    <div className="pic-info"></div>
                                </a>
                            </div>
                            <h1 title="Startup de Marketing Digital" className="titulo-principal">Digital !D</h1>
                            <h3 title="Soluções em gestão online e desenvolvimento" className="sub-titulo">Empresa de estratégia digital</h3>
                            <div className="botoes">
                                <a className="btn-main" title="Assistir" href="/" target="blank">
                                    Assista ao nosso vídeo
                                </a>
                                <a className="btn-main" title="Conheça um pouco sobre nós." href="https://digitalid.com.br/" target="blank">
                                    Visite nosso site
                                </a>
                                <a className="btn-main" title="Fale Conosco." href="http://api.whatsapp.com/send?phone=554197218018&text=Oi,+gostaria+de+saber+mais+sobre+os+serviços+da+Digital+!D" target="blank">
                                    WhatsApp
                                </a>
                                <a className="btn-main" title="Conheça nossos trabalhos." href="https://www.behance.net/sejadigitalid" target="blank">
                                    Portfólio
                                </a>
                            </div>
                            
                            <div className="localizacao">
                                <div className="endereco-main">
                                    <h5>
                                        Av. Presidente Affonso Camargo, 2467 -
                                        Jardim Botânico, Curitiba - PR
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Fundo;