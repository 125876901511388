import React from 'react';

import Fundo from './Components/Background';
import Footer from './Components/Footer';

const App = () => {
  return (
    <>
      <Fundo/>
      <Footer/>
    </>
  )
}
export default App;
